import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import cls from "classnames";
import {
  DISABLE_POSITION,
  FINISH_PRODUCT_TOUR,
  POSITION_MAIN_LAYOUT,
} from "@share/configs/const";
import { setPassioPageProfile } from "@features/main/store/passioPageProfileSlice";
import { selectProfile, setProfile } from "@features/user/userSlice";
import {
  fetchPassioPageProfile,
  finishProductTour,
  updateCreator,
} from "@share/api";
import userApi from "@features/user/userApi";
import { disableEnablePosition } from "@features/main/store/positionsSlice";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import gaEvents from "@share/lib/ga-events";
import { CATEGORY_CONFIG } from "@share/lib/ga-events";
import { placeHolderImageIcon } from "@share/icons/product-tour-icon";
import {
  PRODUCT_TOUR_CLASS_NAME,
  steps,
  STEP_INDEX,
} from "./product-tour-config";
import ProductTourComponent from "@share/components/ProductTourComponent";
import { useSelector } from "react-redux";
import { FINISH_PRODUCT_TOUR_ENUM } from "@features/main/store/interface";
import { setCreatorProfile } from "@features/main/store/creatorProfileSlice";
import Portal from "@share/HOC/portal";
import { CloseIcon } from "@share/icons";

const ProductTour = (props) => {
  const { handleOpenProductTour, isProductTourManual } = props;

  const { t } = useTranslation();
  const productTourRef = useRef(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const domain = user?.kol?.domain;
  const userId = user?.kol?._id;
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const isEnableHeaderDynamic = dynamicConfig?.isEnableHeaderDynamic;
  const [prevStepIndex, setPrevStepIndex] = useState(0);
  const [check, setCheck] = useState(true);
  const [checkComplete, setCheckComplete] = useState(true);
  const classHiddenCloseTooltip = isProductTourManual
    ? ""
    : "hidden-close-tooltip";

  const hiddenCloseTooltipSteps = [
    // STEP_INDEX.STEP_1,
    // STEP_INDEX.STEP_2,
    // STEP_INDEX.STEP_3,
  ];

  const stepsDefault = steps.map((ele, idx) => ({
    title:
      `<div class="wrapper-image">` +
      placeHolderImageIcon +
      `
          <img class="img-product-tour" src=${ele.image_header} /> </div>
          <div class="title">${t(ele.title_key)}<div/>`,
    element: ele.using_selector
      ? document.querySelector(`.${ele.element_class}`)
      : `.${ele.element_class}`,
    intro: `<div> ${t(ele.intro_key)}<div/>`,
    tooltipClass: cls(
      "tooltip-product-tour",
      !isProductTourManual && idx === STEP_INDEX.STEP_16
        ? ele.tooltip_class.replace("show-label-text", "")
        : ele.tooltip_class,
      hiddenCloseTooltipSteps?.includes(idx) && classHiddenCloseTooltip
    ),
    position: ele.position || "auto",
  }));

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const setRef = (ref) => {
    productTourRef.current = ref;
  };

  const onExit = () => {};
  const onBeforeExit = (stepIndex) => {
    if (check) {
      // tách GA step 17 để tracking riêng do step cuối bị duplicate
      if (isProductTourManual && prevStepIndex !== STEP_INDEX.STEP_16) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.PRODUCT_TOUR,
          `ProductTour_Step${stepIndex + 1}`,
          domain,
          `Step ${stepIndex + 1}`
        );
      }
      if (!isProductTourManual && prevStepIndex !== STEP_INDEX.STEP_17) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.PRODUCT_TOUR_AUTO,
          `ProductTourAuto_Step${stepIndex + 1}`,
          domain,
          `Step ${stepIndex + 1}`
        );
      }
      setCheck(false);
      const castWindow = window as any;
      castWindow.triggerVisiblePopoverBlock(false);
      castWindow.triggerVisiblePopoverHeader(false);
      onCompleteProductTour();
    }
  };

  const handleEnableHeader = () => {
    const enableValue = DISABLE_POSITION;
    dispatch(
      disableEnablePosition({
        enableValue,
        positionSlug: POSITION_MAIN_LAYOUT.HEADER,
      })
    );
  };

  const onChangeProductTour = (nextStepIndex, nextElement) => {
    const castWindow = window as any;

    //VisiblePopoverBlock
    if (nextStepIndex === STEP_INDEX.STEP_2 && prevStepIndex < nextStepIndex) {
      castWindow.triggerVisiblePopoverBlock(true);
    }
    if (nextStepIndex === STEP_INDEX.STEP_3) {
      productTourRef.current.updateStepElement(STEP_INDEX.STEP_3);
    }
    if (
      (nextStepIndex === STEP_INDEX.STEP_5 && prevStepIndex < nextStepIndex) ||
      (nextStepIndex === STEP_INDEX.STEP_1 && prevStepIndex > nextStepIndex)
    ) {
      castWindow.triggerVisiblePopoverBlock(false);
    }
    if (nextStepIndex === STEP_INDEX.STEP_4 && prevStepIndex > nextStepIndex) {
      castWindow.triggerVisiblePopoverBlock(true);
    }
    //VisiblePopoverHeader
    if (nextStepIndex === STEP_INDEX.STEP_8 && prevStepIndex < nextStepIndex) {
      castWindow.triggerVisiblePopoverHeader(true);
    }
    if (nextStepIndex === STEP_INDEX.STEP_9) {
      productTourRef.current.updateStepElement(STEP_INDEX.STEP_9);
    }
    if (nextStepIndex === STEP_INDEX.STEP_10) {
      productTourRef.current.updateStepElement(STEP_INDEX.STEP_10);
    }

    if (
      (nextStepIndex === STEP_INDEX.STEP_12 && prevStepIndex < nextStepIndex) ||
      (nextStepIndex === STEP_INDEX.STEP_7 && prevStepIndex > nextStepIndex)
    ) {
      castWindow.triggerVisiblePopoverHeader(false);
    }
    if (nextStepIndex === STEP_INDEX.STEP_11 && prevStepIndex > nextStepIndex) {
      castWindow.triggerVisiblePopoverHeader(true);
    }

    setPrevStepIndex(nextStepIndex);
  };

  useEffect(() => {
    if (prevStepIndex === STEP_INDEX.STEP_9) {
      document
        .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_9}`)
        .classList.add("step-9");
      if (
        document
          .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_10}`)
          .classList.contains("step-10")
      ) {
        document
          .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_10}`)
          .classList.remove("step-10");
      }
    }
    if (prevStepIndex === STEP_INDEX.STEP_10) {
      document
        .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_10}`)
        .classList.add("step-10");
      if (
        document
          .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_9}`)
          .classList.contains("step-9")
      ) {
        document
          .querySelector(`.${PRODUCT_TOUR_CLASS_NAME.STEP_9}`)
          .classList.remove("step-9");
      }
    }
  }, [prevStepIndex]);

  const onCompleteProductTour = async () => {
    if (checkComplete) {
      setCheckComplete(false);
      handleOpenProductTour();
      if (!isProductTourManual) {
        let res = null;
        try {
          res = await updateCreator({
            finish_product_tour: FINISH_PRODUCT_TOUR_ENUM.TRUE,
          });
        } catch (error) {
          console.log(error);
        }
        dispatch(
          setCreatorProfile({
            data: { finish_product_tour: FINISH_PRODUCT_TOUR_ENUM.TRUE },
          })
        );
      }

      if (isEnableHeaderDynamic) {
        handleEnableHeader();
      }
      dispatch(setDynamicParams({ data: { isOpenProductTourDynamic: false } }));

      if (isProductTourManual && prevStepIndex === STEP_INDEX.STEP_16) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.PRODUCT_TOUR,
          `ProductTour_Step16`,
          domain,
          `Step 16`
        );
      }
      if (!isProductTourManual && prevStepIndex === STEP_INDEX.STEP_17) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.PRODUCT_TOUR_AUTO,
          `ProductTourAuto_Step17`,
          domain,
          `Step 17`
        );
      }
    }
  };

  const stepsMemo = useMemo(() => {
    let stepsMemo = stepsDefault;
    if (isProductTourManual) {
      stepsMemo = stepsMemo.filter((ele, idx) => idx !== STEP_INDEX.STEP_17);
    }
    return stepsMemo;
  }, [isProductTourManual, stepsDefault]);

  return (
    <>
      <ProductTourComponent
        steps={stepsMemo}
        onExit={onExit}
        onBeforeExit={onBeforeExit}
        onChange={onChangeProductTour}
        onComplete={onCompleteProductTour}
        setRef={setRef}
      />
      <Portal elementId="product-tour-skip-button-id">
        <button
          className="fixed top-4 left-4 z-[100000000] bg-[#ee3244ff] py-1 px-2 rounded-full text-base text-white shadow-lg flex flex-row gap-1 items-center"
          onClick={onBeforeExit}
        >
          {t("ss_builder_popover_manual_button_close")}
          <CloseIcon
            width={14}
            height={14}
            onClick={() => {}}
            fillColor="#FFFFFF"
          />
        </button>
      </Portal>
    </>
  );
};

export default ProductTour;
